<template>
  <div class="frelative max-w-screen w-full flex justify-center items-center px-5 md:py-10 pt-0 pb-5 overflow-hidden z-auto">
    <div class="relative w-full max-w-screen-lg flex items-center rounded bg-white shadow-xl p-5 lg:p-10 mx-auto">
      <div class="mx-auto w-full">
        <div class="text-center">
          <h1 class="my-3 text-3xl font-semibold text-gray-700 dark:text-gray-200">
            Nous contacter
          </h1>
          <p class="text-gray-400 dark:text-gray-400">
            Laissez-nous votre message ou avis.
          </p>
        </div>
        <div class="m-7">
          <form
            id="form"
            action="https://api.web3forms.com/submit"
            method="POST"
            @submit.prevent="submitForm"
          >
            <input
              type="hidden"
              name="apikey"
              value="548b91dd-35ad-416a-bde6-5af76576f26d"
            >
            <input
              type="hidden"
              name="subject"
              value="New Submission from Web3Forms"
            >
            <input
              id=""
              type="checkbox"
              name="botcheck"
              style="display: none;"
            >

            <div class="mb-6">
              <label
                for="name"
                class="block mb-2 text-sm text-gray-600 dark:text-gray-400"
              >Nom</label>
              <input
                id="name"
                type="text"
                name="name"
                placeholder="Jean mark"
                required
                class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
              >
            </div>
            <div class="mb-6">
              <label
                for="email"
                class="block mb-2 text-sm text-gray-600 dark:text-gray-400"
              >Adresse e-mail</label>
              <input
                id="email"
                type="email"
                name="email"
                placeholder="email@outlook.fr"
                required
                class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
              >
            </div>
            <div class="mb-6">
              <label
                for="message"
                class="block mb-2 text-sm text-gray-600 dark:text-gray-400"
              >Votre Message</label>

              <textarea
                id="message"
                rows="5"
                name="message"
                placeholder="Votre Message"
                class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                required
              />
            </div>
            <div class="mb-6">
              <button
                type="submit"
                class="w-full px-3 py-4 text-white bg-indigo-500 rounded-md focus:bg-indigo-600 focus:outline-none"
              >
                Envoyer
              </button>
            </div>
            <p
              id="result"
              class="text-base text-center text-gray-400"
            >
              {{ myResult }}
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Contact',
  layout: 'default',
  data () {
    return {
      myResult: ''
    }
  },
  methods: {
    submitForm () {
      const form = document.getElementById('form')
      const formData = new FormData(form)
      var object = {}
      formData.forEach((value, key) => {
        object[key] = value
      })
      var json = JSON.stringify(object)
      this.myResult = "Patientez s'il-vous plait..."

      fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: json
      })
        .then(async (response) => {
          const json = await response.json()
          if (response.status === 200) {
            this.myResult = 'Votre message a été transmis!'
          } else {
            console.log(response)
            this.myResult = json.message
          }
        })
        .catch(error => {
          console.log(error)
          this.myResult = 'Nous recontrons une erreur!'
        })
        .then(function () {
          form.reset()
        })
    }
  }
}
</script>
